.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: sans-serif;
}
#simple-cookie-consent {
  color: black;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  resize: vertical;
  overflow: auto;
  z-index: 99;
  background: gray;
}
#simple-cookie-consent .cookie-consent-container {
  position: absolute;
  top: 50%;
  left: 20px;
  right: 20px;
  margin: -100px auto 0;
  background: #fff;
  padding: 20px;
  max-width: 500px;
}
#simple-cookie-consent .cookie-consent-selection {
  text-align: right;
}
#simple-cookie-consent button {
  border: none;
  padding: 10px 20px;
  margin: 10px 0 0 10px;
  background: none;
  font-size: 1.1em;
}
#simple-cookie-consent button.cookie-consent-allow {
  background-color: #04aa6d;
  color: #fff;
}
#simple-cookie-consent button.cookie-consent-allow:focus,
#simple-cookie-consent button.cookie-consent-allow:hover {
  background-color: #059862;
  cursor: pointer;
}
#simple-cookie-consent button.cookie-consent-deny {
  padding: 5px 0;
  font-size: 0.9em;
  opacity: 0.8;
}
#simple-cookie-consent button.cookie-consent-deny:focus,
button.cookie-consent-deny:hover {
  opacity: 1;
  cursor: pointer;
}
#simple-cookie-consent hr {
  margin: 15px 0;
}